body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

#pro-tip {
    /* z-index: -1; */
    overflow: hidden;
}

#hero-hand-1{
    /* opacity: 0; */
}

#hero-hand-2{
    /* opacity: 0; */
}

@media (prefers-reduced-motion: no-preference) {
    #pro-tip {
      animation: smoothUpWFadeIn 3s forwards;
    } 
    #hero-hand-1 {
      animation: smoothInWFadeInLeft 1.25s forwards;
    } 
    #hero-hand-2 {
      animation: smoothInWFadeInRight 2.25s forwards;
    } 
  }
  

  @keyframes smoothUpWFadeIn {
    from {
      opacity: 0;
      top:0px;
      height:0px;
    }
    to {
      top:-75px;
      opacity: 1;
      height: 80px;
  
    }
  }
  @keyframes smoothInWFadeInLeft {
    from {
      opacity: 0;
      /* height:0%; */
      bottom:-65%;
    }
    to {
      opacity: 1;
      bottom:0%;
      /* height: 70%; */
  
    }
  }
  @keyframes smoothInWFadeInRight {
    from {
      opacity: 0;
      /* height:0%; */
      bottom:-65%;
    }
    to {
      opacity: 1;
      bottom:0%;
      /* height: 70%; */
  
    }
  }